import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Form, Row, Col, Button } from 'react-bootstrap'
import music from '../assets/music/music1.aac'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/gold3.css'
import path from '../assets/img/gold3/path1.svg'
import wed2 from '../assets/img/gold3/wed2.jpg'
import manten from '../assets/img/gold3/manten.jpg'
import burung from '../assets/img/gold3/burung.svg'
import logoig from '../assets/img/gold3/logoig.svg'
import logoig1 from '../assets/img/gold3/logoig1.svg'
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });
        let acara = [[
            'Akad Nikah',
            '10 oktober 2020',
            'Masjid Roudotul Muchlisin',
            '08:00 - 10:00 WIB'
        ],
        [
            'Resepsi',
            '10 oktober 2020',
            'Ijen View Hotel and Lounge',
            '17:00 - 21:00 WIB'
        ]
        ]
        this.setState({ acara: acara })
        var countDownDate = new Date("10/10/2021").getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);

    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }
    googleCalendar = () => {
        window.open(`https://calendar.google.com/calendar/r/eventedit?
  text=Event%20Title
  &details=Pernikahan%20Willy%20dan%20Jessica
  &dates=20201010T100000/20201010T113000
  &ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
      `, '_blank')
    }
    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
      }

    render() {
        let { acara, days, hours, minutes, seconds, hide, } = this.state

        return (
            <>
                <Helm
                    title='Undanganku - Gold 3'
                    desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
                    logo={logo}
                />
                <div id='gold3'>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <Container fluid id='g3-header' className='relative'>
                        <h2 className='roboto-slab'>
                            We Are Getting Married
                        </h2>
                        <h1 className='sacramento pt-5 pb-5'>
                            <div className='d-block'>Kusuma </div>
                            <div className='d-block'>&</div>
                            <div className='d-block'> Mawar</div>
                        </h1>
                        <h2 className='roboto-slab'>
                            10 . 10 . 2021
                        </h2>
                        <Row className='justify-content-center'>
                            <div onClick={() => { this.play() }}
                                className={`col-md-4 button  btn roboto-slab ${hide ? 'show' : 'hide'}`}>
                                Open Invitation
                            </div>
                        </Row>
                        <Col className={`absolute path ${hide ? 'hide' : 'show'}`} xs={12}>
                            <img className='img-fluid w-100' src={path} />
                        </Col>
                    </Container>
                    {!hide ? (
                        <>
                            <Container fluid className='bg-white pt-3 pb-3' id='top'>
                                <Row className='justify-content-center'>
                                    <Col xs={10} md={6} className='bg-pink p-3 rounded'>
                                        <Item>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {days}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Days
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {hours}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Hours
                                    </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div >
                                                        {minutes}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Mins
                      </span>
                                                </Item>
                                            </div>
                                            <div className='dot' >:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {seconds}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Secs
                      </span>
                                                </Item>
                                            </div>
                                        </Item>
                                    </Col>
                                </Row>
                                <Item>
                                    <Col xs={4} md={2} className='p-3'>
                                        <img src={burung} className='img-fluid w-100' />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic c-main'>
                                            " Hakikat Pernikahan adalah menggenapkan yang belum genap dan menyempurnakan yang kurang sempurna"
                            </p>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='p-3'>
                                        <img src={wed2} className='img-fluid w-100 rounded' />
                                    </Col>
                                    <div className='p-3'>
                                        <Item>
                                            <Col xs={12} md={10} className='p-3 bg-pink rounded'>
                                                <p className='c-main'>
                                                    Maha Suci Allah yang telah menciptakan Makhluk-Nya secara Berpasang-pasangan.
                                    <br></br><br></br>
                                    Ya Allah Perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri Kami
                                    </p>
                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={manten} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main sacramento p-3'>
                                                        Kusuma Cahya
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className='c-main s-bold poppins'>Putra Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='c-main poppins'>
                                                        Bapak Putu Mega<br />
                                            dan<br />
                                            Ibu Eka Dian
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}>
                                                        <img src={logoig} className='img-fluid w-100 p-1' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main sacramento p-3 larger'>
                                                        &
                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={manten} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main sacramento p-3'>
                                                        Putu Mawar
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className='c-main s-bold poppins'>Putri Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='c-main poppins'>
                                                        Bapak Putu Mega<br />
                                            dan<br />
                                            Ibu Eka Dian
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}>
                                                        <img src={logoig} className='img-fluid w-100 p-1' />
                                                    </Col>
                                                </Item>

                                            </Col>
                                        </Item>
                                    </div>
                                </Item>
                            </Container>
                            <Container fluid className='bg-white pt-3 pb-3' id='save'>
                                <h1 className='poppins s-bold c-grey'>
                                    Save The Date
                        </h1>
                                <h1 className='sacramento s-bold mt-3 c-main f-mid'>
                                    Resepsi
                        </h1>
                                <p className='f-small poppins mt-4'>
                                    <div className='s-bold '>
                                        Rabu, 17 Oktober 2020
                                </div>
                            18.00-21.00<br />
                            Gedung Lorem Ipsum, Surabaya<br />
                            Jawa Timur
                        </p>
                                <Item>
                                    <Col xs={8} md={4} className='button poppins c-white f-small rounded p-1 btn ' >
                                        Get Direction
                            </Col>
                                </Item>
                                <Item>
                                    <Col xs={8} md={4} className='button poppins c-white f-small rounded p-1 mt-3 btn' >
                                        Add to Google Calendar
                            </Col>

                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='border-pink poppins c-grey rounded p-2'>
                                        Tanpa Mengurangi Rasa Hormat semua tamu harap menerapkan Protokol Kesehatan denganmenggunakan masker kedalam ruangan Resepsi dan menggunakan hand Sanitizer yang telah disediakan.
                            <br />
                            Terima Kasih
                            </Col>
                                </Item>


                            </Container >
                            <Container fluid id='slider' className='bg-pink p-3'>
                                <Item><Col xs={12} md={8}>
                                    <Slider />
                                </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='pt-5'>
                                        <iframe className='img-fluid w-100' height="480" src="https://www.youtube.com/embed/_GUEOfnm8u8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </Col>
                                </Item>



                            </Container>
                            <Container className='bg-pink' fluid id='form'>
                                <Item>

                                    <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4'>
                                        <h1 className='sacramento c-main f-small send'>
                                            Send Your Whises
                        </h1>
                                        <form className="col-12 w-100 ">
                                            <input type='text' className="col-12 w-100 text-center" placeholder="Nama" />
                                            <input type='text' className="col-12 w-100 text-center" placeholder="Alamat" />
                                            <input type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" />
                                            <Item>
                                                <input id="option" type="radio" name="field" value="option" defaultChecked />
                                                <label for="option"><span><span></span></span>Hadir</label>
                                                <input id="option" type="radio" name="field" value="option" />
                                                <label for="option"><span><span></span></span>Tidak Hadir</label>
                                            </Item>
                                            <Item>
                                                <div className='col-6 button rounded'> Kirim </div>
                                            </Item>
                                        </form>

                                    </Col>
                                </Item>

                                <Item>
                                    <h1 className='sacramento c-main f-small send pt-3 pb-3'>
                                        Cahya & Mawar
                        </h1>
                                </Item>
                            </Container>
                            <Container fluid className='bg-pink relative p-0' id='path'>
                                <img className='absolute img-fluid w-100 mx' src={path} />
                            </Container>
                            <Foot ig={logoig} />
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}